import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import "./styles/_index.scss";

interface FileData {
  video_files: { title: string }[];
  gif_files: { title: string }[];
}
interface ApiResponse {
  files_by_dropdown: Record<string, FileData>;
  drop_down_list_names: string[];
}
const Charts: React.FC = () => {
  const [filesByDropdown, setFilesByDropdown] = useState<
    Record<string, FileData>
  >({});
  const [dropdownNames, setDropdownNames] = useState<string[]>([]);
  const [selectedGif, setSelectedGif] = useState<Record<string, string>>({
    Wind: "",
    Wave: "",
    Current: "",
  });
  const [selectedVideo, setSelectedVideo] = useState<Record<string, string>>({
    Wind: "",
    Wave: "",
    Current: "",
  });
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/api/api/files/`);
        const data: ApiResponse = await response.json();
        if (
          data.files_by_dropdown &&
          Array.isArray(data.drop_down_list_names)
        ) {
          setFilesByDropdown(data.files_by_dropdown);
          setDropdownNames(data.drop_down_list_names);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    fetchFiles();
  }, []);
  const handleGifSelect = (
    event: SelectChangeEvent<string>,
    category: "Wind" | "Wave" | "Current"
  ) => {
    setSelectedGif((prev) => ({ ...prev, [category]: event.target.value }));
  };
  const handleVideoSelect = (
    event: SelectChangeEvent<string>,
    category: "Wind" | "Wave" | "Current"
  ) => {
    setSelectedVideo((prev) => ({ ...prev, [category]: event.target.value }));
  };
  const getFileUrl = (
    dropdownName: string,
    category: "Wind" | "Wave" | "Current",
    type: "gif" | "video"
  ) => {
    const files = filesByDropdown[dropdownName];
    if (!files) return "";
    const fileArray = type === "gif" ? files.gif_files : files.video_files;
    const categoryPattern = new RegExp(
      category.toLowerCase().replace(/\s+/g, "\\s*")
    );
    const file = fileArray.find((file) =>
      categoryPattern.test(file.title.toLowerCase())
    );
    const encodedUrl = file
      ? `${process.env.REACT_APP_BACKEND_IP}/api/files/${encodeURIComponent(file.title)}`
      : "";
    console.log("Encoded URL:", encodedUrl);
    return encodedUrl;
  };

  return (
    <div className="container1">
      <div className="section">
        <h2>GIF</h2>
        <div className="dropdown-row">
          {["Wind", "Wave", "Current"].map((category) => (
            <div key={category} className="dropdown">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{category}</InputLabel>
                <Select
                  value={selectedGif[category as "Wind" | "Wave" | "Current"]}
                  onChange={(e) =>
                    handleGifSelect(e, category as "Wind" | "Wave" | "Current")
                  }
                  label={category}
                >
                  {dropdownNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>
        <div className="media-display">
          {selectedGif.Wind && (
            <img
              className="gif"
              src={getFileUrl(selectedGif.Wind, "Wind", "gif")}
              alt="Wind GIF"
            />
          )}
          {selectedGif.Wave && (
            <img
              className="gif"
              src={getFileUrl(selectedGif.Wave, "Wave", "gif")}
              alt="Wave GIF"
            />
          )}
          {selectedGif.Current && (
            <img
              className="gif"
              src={getFileUrl(selectedGif.Current, "Current", "gif")}
              alt="Current GIF"
            />
          )}
        </div>
      </div>
      <div className="section">
        <h2>MP4</h2>
        <div className="dropdown-row">
          {["Wind", "Wave", "Current"].map((category) => (
            <div key={category} className="dropdown">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{category}</InputLabel>
                <Select
                  value={selectedVideo[category as "Wind" | "Wave" | "Current"]}
                  onChange={(e) =>
                    handleVideoSelect(
                      e,
                      category as "Wind" | "Wave" | "Current"
                    )
                  }
                  label={category}
                >
                  {dropdownNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>
        <div className="media-display">
          {selectedVideo.Wind && (
            <video
              className="video"
              controls
              src={getFileUrl(selectedVideo.Wind, "Wind", "video")}
            />
          )}
          {selectedVideo.Wave && (
            <video
              className="video"
              controls
              src={getFileUrl(selectedVideo.Wave, "Wave", "video")}
            />
          )}
          {selectedVideo.Current && (
            <video
              className="video"
              controls
              src={getFileUrl(selectedVideo.Current, "Current", "video")}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Charts;
